//HomePage.js

const HomePage = () => {
  return (
    <div>
      <h1>Welcome to Burrito Slut!</h1>
      <p>Find the best burritos around you and share your burrito experiences with others.</p>
    </div>
  );
};

export default HomePage;
